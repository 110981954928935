/*global $*/

class SconePalace {
    constructor() {
        this.initIFrames();
        this.initMenu();
        this.initStickyMenu();
        this.smoothScroll();
        this.initMatchHeight();
        this.initAos();
        this.backToTop();
        this.initReadmore();
        console.log('Scone Palace initialized');
    }

    initSplash() {
        $('#splash').fadeOut(200);
    }

    initAos() {
        $(function () {
            var AOS = require('aos');
            AOS.init({
                offset: 100,
                duration: 700,
                easing: "ease-out-quad",
            });
            window.addEventListener('load', AOS.refresh);
            document.querySelectorAll('img')
                .forEach((img) =>
                    img.addEventListener('load', () =>
                        AOS.refresh()
                    )
                );
        });
    }

    backToTop() {
        var backtop = $('#to-top');

        $(window).scroll(function () {
            if ($(window).scrollTop() > 400) {
                backtop.addClass('show-btt');
            } else {
                backtop.removeClass('show-btt');
            }
        });

        backtop.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '400');
        });
    }

    initReadmore() {
        $('.readmore').readmore({
            speed: 100,
            //collapsedHeight: 267,
            moreLink: '<a class="read-MoreLess-btn" href="#">Read More <i class="fa fa-angle-down"></i></a>',
            lessLink: '<a class="read-MoreLess-btn" href="#">Read Less <i class="fa fa-angle-up"></i></a>',
        });
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 250) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });

        $(".navbar-toggler").on('click', function () {
            $('body').toggleClass("menuOpen");
            $('.navbar-collapse').toggleClass("open");
        });
    }

    smoothScroll() {
        $('a[href^="#"]').on('click', function (e) {
            e.preventDefault();
            var target = this.hash;
            var $target = $(target);
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 55
            }, 900, 'swing', function () {
            });
        });
    }

    initMenu() {
        //console.clear();
        const navExpand = [].slice.call(document.querySelectorAll('.dropdown'))
        const backLink = `<a class="nav-back-link" href="javascript:;"><i class="arrow-back">Left</i></a>`
        navExpand.forEach(item => {
            item.querySelector('.dropdown-menu').insertAdjacentHTML('afterbegin', backLink)
            item.querySelector('.nav-link').addEventListener('click', () => item.classList.add('active'))
            item.querySelector('.nav-back-link').addEventListener('click', () => item.classList.remove('active'))
        })
    }

    initIFrames() {
        let resizeIframe = function () {
            $.each($('.iframe'), (i, iframe) => {
                let ratio = $(iframe).data('ratio');
                let iframeTag = $(iframe).find('iframe');
                iframeTag.height(iframeTag.width() / ratio);
            });
        }
        $(window).resize(() => {
            resizeIframe();
        });
        resizeIframe();
    }

    initMatchHeight() {
        $(function () {
            $('.matrix-content .squareCallToActions .call-to-action .title').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });

            $('.arched .tdes').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });

            $('.news-square .content').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });

            $('.event-card .title').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });

            $('.bigCallToActions  .title').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });

            $('.search-square-t').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
            
        });
    }
}

export default SconePalace;