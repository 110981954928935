/*global $*/

class EventTracking
{
    constructor() 
    {
        $('a').each(function() {
            var anchorTxt = $(this).text();
            anchorTxt = anchorTxt.toLowerCase();
            if (anchorTxt.indexOf('visit') != -1 || anchorTxt.indexOf('plan your visit') != -1) {
                $(this).on('click', function(){
                    dataLayer.push({'event':'plan_your_visit_button_click'});
                });
            } else if(anchorTxt.indexOf('shop') != -1) {
                $(this).on('click', function(){
                    dataLayer.push({'event':'shop_button_click'});
                });
            } else if(anchorTxt.indexOf('contact') != -1 || anchorTxt.indexOf('speak to our team') != -1 || anchorTxt.indexOf('get in touch') != -1) {
                $(this).on('click', function(){
                    dataLayer.push({'event':'contact_button_click'});
                });
            } else if(anchorTxt.indexOf('book') != -1) {
                $(this).on('click', function(){
                    dataLayer.push({'event':'book_now_button_click'});
                });
            }
        });
        console.log('Event Tracking initialized');
    }
}

export default EventTracking;