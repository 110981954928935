/*global $*/

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';

class Gallery
{
    constructor() 
    {
        this.menu = $('.gallery-menu');
        this.activeGallery = $('.gallery-images:visible');
        this.initMenu();
        this.initPopState();
        this.initLightGallery();
        console.log('Gallery initialized');
    }

    initLightGallery()
    {
        $.each($('body.gallery .light-gallery'), function (i, gallery) {
            lightGallery(gallery, {
                plugins: [lgZoom]
            });
        });
    }

    initMenu() 
    {
        let _this = this;
        this.menu.find('a').click(function (e) {
            e.preventDefault();
            let id = $(this).data('id');
            _this.showGallery(id);
            _this.pushState(id);
        });
    }

    initPopState()
    {
        let _this = this;
        window.addEventListener('popstate', function() {
            let query = window.location.search;
            query = query.replace('?', '').split('=');
            if (query.length > 1) {
                _this.showGallery(query[1]);
            }
        });
    }

    pushState(galleryId)
    {
        let url = window.location.origin + window.location.pathname + '?category=' + galleryId;
        history.pushState(null, null, url);
    }

    showGallery(galleryId)
    {
        this.activeGallery.fadeOut('fast', () => {
            this.activeGallery = $('#gallery-'+galleryId);
            this.activeGallery.fadeIn('fast');
            this.menu.find('a.active').removeClass('active');
            this.menu.find('a[data-id='+galleryId+']').addClass('active');
        });
    }
}

export default Gallery;